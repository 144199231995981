@mixin use-md-icon($icon) {
  font-family: 'Material Icons Outlined';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 200;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &:before {
    content: $icon;
  }

}

.bankAccountsIcon2 .p-menuitem-icon {
  @include use-md-icon('account_balance');
}


.mi {

  &.mi-calendar_month {
    @include use-md-icon('calendar_month');
  }

  &.mi-menu-book {
    @include use-md-icon('menu_book');
  }

  &.mi-key {
    @include use-md-icon('key');
  }

  &.mi-query-stats {
    @include use-md-icon('query_stats');
  }

  &.mi-library-books {
    @include use-md-icon('library_books');
  }

  &.mi-description {
    @include use-md-icon('description');
  }

  &.mi-file-present {
    @include use-md-icon('file_present');
  }

  &.mi-local-post-office {
    @include use-md-icon('local_post_office');
  }

  &.mi-library-books {
    @include use-md-icon('library_books');
  }

  &.mi-receipt-long {
    @include use-md-icon('receipt_long');
  }

  &.mi-manage-accounts {
    @include use-md-icon('manage_accounts');
  }

  &.mi-quiz {
    @include use-md-icon('quiz');
  }

  &.mi-dark-mode {
    @include use-md-icon('dark_mode');
  }

  &.mi-dataset-linked {
    @include use-md-icon('dataset_linked');
  }

  &.mi-credit-score {
    @include use-md-icon('credit_score');
  }



  &.mi-groups {
    @include use-md-icon('groups');
  }

  &.mi-group {
    @include use-md-icon('group');
  }


  &.mi-account-balance {
    @include use-md-icon('account_balance');
  }

  &.mi-list-alt {
    @include use-md-icon('list_alt');
  }

  &.mi-apps {
    @include use-md-icon('apps');
  }

  &.mi-mi-edit {
    @include use-md-icon('edit');
  }

  &.mi-mi-edit-off {
    @include use-md-icon('edit_off');
  }


  &.mi-delete {
    @include use-md-icon('delete');
  }

  &.mi-list {
    @include use-md-icon('list');
  }

  &.mi-assignment-late {
    @include use-md-icon('assignment_late');
  }


  &.mi-warning {
    @include use-md-icon('warning');
  }

  &.mi-gavel {
    @include use-md-icon('gavel');
  }

  &.mi-event {
    @include use-md-icon('event');
  }

  &.mi-dynamic-feed {
    @include use-md-icon('dynamic_feed');
  }


  &.mi-call-split {
    @include use-md-icon('call_split');
  }

  &.mi-handshake {
    @include use-md-icon('handshake');
  }

  &.mi-storefront {
    @include use-md-icon('storefront');
  }

  &.mi-person {
    @include use-md-icon('person');
  }

  &.mi-mail {
    @include use-md-icon('mail');
  }

  &.mi-location-on {
    @include use-md-icon('location_on');
  }

  &.mi-local-mall {
    @include use-md-icon('local_mall');
  }

  &.mi-local-offer {
    @include use-md-icon('local_offer');
  }

  &.mi-room-service {
    @include use-md-icon('room_service');
  }

  &.mi-factory {
    @include use-md-icon('factory');
  }

  &.mi-post-add {
    @include use-md-icon('post_add');
  }

  &.mi-pets {
    @include use-md-icon('pets');
  }

  &.mi-change-circle {
    @include use-md-icon('change_circle');
  }

  &.mi-trending-up {
    @include use-md-icon('trending_up');
  }

  &.mi-task-alt {
    @include use-md-icon('task_alt');
  }

  &.mi-trending-down {
    @include use-md-icon('trending_down');
  }

  &.mi-currency-exchange {
    @include use-md-icon('currency_exchange');
  }

  &.mi-enhanced-encryption {
    @include use-md-icon('enhanced_encryption');
  }



  &.mi-private-connectivity {
    @include use-md-icon('private_connectivity');
  }

  &.mi-task {
    @include use-md-icon('task');
  }

  &.mi-work-outline {
    @include use-md-icon('work_outline');
  }

  &.mi-file-copy {
    @include use-md-icon('file_copy');
  }

  &.mi-assignment-ind {
    @include use-md-icon('assignment_ind');
  }

  &.mi-assignment-turned-in {
    @include use-md-icon('assignment_turned_in');
  }

  &.mi-snippet-folder {
    @include use-md-icon('snippet_folder');
  }

  &.mi-diversity-1 {
    @include use-md-icon('diversity_1');
  }


  &.mi-checklist {
    @include use-md-icon('checklist');
  }

  &.mi-family-restroom {
    @include use-md-icon('family_restroom');
  }


  &.mi-computer {
    @include use-md-icon('computer');
  }

  &.mi-settings {
    @include use-md-icon('settings');
  }

  &.mi-settings-applications {
    @include use-md-icon('settings_applications');
  }

  &.mi-view-list {
    @include use-md-icon('layers');
  }


  &.mi-room-preferences {
    @include use-md-icon('room_preferences');
  }

  &.mi-person {
    @include use-md-icon('person');
  }

  &.mi-real-estate-agent {
    @include use-md-icon('real_estate_agent');
  }


  &.mi-refresh {
    @include use-md-icon('refresh');
  }


  &.mi-format-list-numbered {
    @include use-md-icon('format_list_numbered');
  }

  &.mi-apartment {
    @include use-md-icon('apartment');
  }


  &.mi-payments {
    @include use-md-icon('payments');
  }

  &.mi-article {
    @include use-md-icon('article');
  }

  &.mi-note-add {
    @include use-md-icon('note_add');
  }

  &.mi-storefront {
    @include use-md-icon('storefront');
  }


  &.mi-link {
    @include use-md-icon('link');
  }

  &.mi-link-off {
    @include use-md-icon('link_off');
  }

  &.mi-edit {
    @include use-md-icon('edit');
  }

  &.mi-edit-off {
    @include use-md-icon('edit_off');
  }

  &.mi-delete {
    @include use-md-icon('delete');
  }


  &.mi-badge {
    @include use-md-icon('badge');
  }


  &.mi-highlight-off {
    @include use-md-icon('highlight_off');
  }


  &.mi-school {
    @include use-md-icon('school');
  }


  &.mi-location-city {
    @include use-md-icon('location_city');
  }


  &.mi-business {
    @include use-md-icon('business');
  }


  &.mi-energy-savings-leaf {
    @include use-md-icon('energy_savings_leaf');
  }


  &.mi-map {
    @include use-md-icon('map');
  }

  &.mi-grid-on {
    @include use-md-icon('grid_on');
  }

  &.mi-text-snippet {
    @include use-md-icon('text_snippet');
  }

  &.mi-lock {
    @include use-md-icon('lock');
  }

  &.mi-sell {
    @include use-md-icon('sell');
  }

  &.mi-gas-meter {
    @include use-md-icon('gas_meter');
  }

  &.mi-123 {
    @include use-md-icon('123');
  }

  &.mi-electric-meter {
    @include use-md-icon('electric_meter');
  }


  &.mi-filter {
    @include use-md-icon('filter');
  }

  &.mi-logout {
    @include use-md-icon('logout');
  }

  &.mi-inventory {
    @include use-md-icon('inventory');
  }

  &.mi-exit-to-app {
    @include use-md-icon('exit_to_app');
  }

  &.mi-disabled-by-default {
    @include use-md-icon('disabled_by_default');
  }


  &.mi-square-foot {
    @include use-md-icon('square_foot');
  }

  &.mi-border-clear {
    @include use-md-icon('border_clear');
  }


  &.mi-meeting-room {
    @include use-md-icon(' meeting_room');
  }

  &.mi-aspect-ratio {
    @include use-md-icon('aspect_ratio');
  }

  &.mi-bedroom-parent {
    @include use-md-icon('bedroom_parent');
  }

  &.mi-local-parking {
    @include use-md-icon('local_parking');
  }


  &.mi-home-work {
    @include use-md-icon('home_work');
  }


  &.mi-reviews {
    @include use-md-icon('reviews');
  }


  &.mi-rate-review {
    @include use-md-icon('rate_review');
  }

  &.mi-error-outline {
    @include use-md-icon('error_outline');
  }

}
