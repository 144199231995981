/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import '../../../node_modules/material-icons/iconfont/material-icons';
@import "abstracts/all-abstracts";
@import "themes/theme-override";


body {
  font-family: 'Open Sans', 'Roboto';
  background-color: #171717!important;
  line-height: 1.5;
  height: 100%;
  min-height: 100vh;
}

::ng-deep {
  .p-component {
    font-family: 'Open Sans', 'Roboto';
  }
}



.page-loaded {
  height: 100%;
}



// Definiere die primäre Farbe
$primary-color: #0071e0;
$secondary-color: #0352A1;


$primary-bg-color: #0B121A;
$secondary-bg-color: #161D24;

$secondary-border-color: #2A2E33;


//$background-color: #2a323d;

.success-color {
  color: #0ede0e;
}

.danger-color {
  color: #ff3229;
}

.warning-color {
  color: #e86b02;
}


.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}


::ng-deep {

  .p-overlay {
    width: 100%;
    min-width: 250px;
    max-width: 500px;
  }

  .p-dropdown-panel {
    width: 100%;
    min-width: 250px;
    max-width: 500px;
  }


}


.no-scroll {
  height: 100%;
  overflow: hidden; /* Prevents scrolling */
  touch-action: none; /* Prevents touch scrolling on touch devices */
}


/* TEST DESIGN
::ng-deep {

  .p-button {
    cursor: pointer!important;
  }
  .p-toolbar {
    background-color: $primary-bg-color;
    border-color: $secondary-border-color;
  }
  .p-menubar {
    background-color: $secondary-bg-color;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background-color: $secondary-bg-color;
    border-color: $secondary-border-color;
  }
  .p-datatable .p-datatable-tbody > tr  {
    background-color: $secondary-bg-color;
    color: #d2d2d2;
  }
  .p-chip{
    color: #eaeaea;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    border-color: $secondary-border-color;
  }
  .p-paginator {
    background-color: $primary-bg-color;
  }

}
*/


.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text, .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #fff;
}


@mixin use-md-icon($icon) {
  font-family: 'Material Icons Outlined';
  font-weight: 200;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &:before {
    content: $icon;
  }

}


.mi-link {
  @include use-md-icon('link');
}

.mi-link-off {
  @include use-md-icon('link_off');
}

.mi-edit {
  @include use-md-icon('edit');
}

.mi-edit-off {
  @include use-md-icon('edit_off');
}

.mi-delete {
  @include use-md-icon('delete');
}

.mi-delete-forever {
  @include use-md-icon('delete_forever');
}

.drag-indicator {
  padding-top: 5px;
  @include use-md-icon('drag_indicator');
}


.drag-handle {
  padding-top: 5px;
  @include use-md-icon('drag_handle');
}


.edit-button .p-button-icon {
  @include use-md-icon('edit');
}

.edit-button-off .p-button-icon {
  @include use-md-icon('edit_off');
}

.p-menubar-button {
  color: #4d4d4d !important;
}

.p-menubar-button .pi-bars {
  height: 1.5rem;
  width: 1.5rem;
  color: #4d4d4d !important;
  margin-top: -5px;
  margin-left: 13px;
}


.p-menubar-button .p-icon {
  padding-top: 4px;
  height: 2rem;
  width: 2rem;
}



.relationPicker .p-multiselect-trigger-icon {
  @include use-md-icon('edit_note');
  font-size: 32px;
}

.manage-search-icon .pi {
  @include use-md-icon('manage_search');
}

/*MAIN - NAVIGATION ICONS*/


/*MAIN-MENU-SALES-ICONS*/
.menuSalesIcon .p-menuitem-icon {
  @include use-md-icon('real_estate_agent');
}

.p-submenu-list .menuSalesIcon .p-menuitem-icon {
  @include use-md-icon('real_estate_agent');
}

/*MAIN-MENU-MASTER-ICONS*/
.menuMasterIcon .p-menuitem-icon {
  @include use-md-icon('settings');
}

.p-submenu-list .menuMasterIcon .p-menuitem-icon {
  @include use-md-icon('settings');
}

/*MAIN-MENU-USER-ICONS*/
.menuUserIcon .p-menuitem-icon {
  @include use-md-icon('manage_accounts');
}

.p-submenu-list .menuUserIcon .p-menuitem-icon {
  @include use-md-icon('manage_accounts');
}


.profile-button .p-button-icon {
  @include use-md-icon('person');
}

.profile-button-lock .p-button-icon {
  @include use-md-icon('key');
}


/*OFFICE NAVIGATION ICONS*/


.menuOfficeContactIcon .p-menuitem-icon {
  @include use-md-icon('contact_mail');
}

.menuOfficeMailboxIcon .p-menuitem-icon {
  @include use-md-icon('markunread_mailbox');
}

.writings-icon .p-menuitem-icon {
  @include use-md-icon('mail');
}

.writing-deliveries-icon .p-menuitem-icon {
  @include use-md-icon('local_shipping');
}

.menuOfficeAccountingIcon .p-menuitem-icon {
  @include use-md-icon('menu_book');
}

.menuOfficeAccountingIcon .documentsIcon .p-menuitem-icon {
  @include use-md-icon('description');
}

.menuOfficeAccountingIcon .payrollIcon .p-menuitem-icon {
  @include use-md-icon('receipt_long');
}

.menuOfficeAccountingIcon .bankAccountsIcon .p-menuitem-icon {
  @include use-md-icon('account_balance');
}

.menuOfficeAccountingIcon .transactionsIcon .p-menuitem-icon {
  @include use-md-icon('list_alt');
}

.menuOfficeAccountingIcon .paymentClaimsIcon .p-menuitem-icon {
  @include use-md-icon('assignment_late');
}


.menuOfficeEmployees .p-menuitem-icon {
  @include use-md-icon('badge');
}

.menu-office-vendors .p-menuitem-icon {
  @include use-md-icon('handshake');
}

.menuOfficeContracts .p-menuitem-icon {
  @include use-md-icon('file_present');
}


/*PROPERTIES NAVIGATION ICONS*/


.menuPropertiesSharedUnits .p-menuitem-icon {
  @include use-md-icon('meeting_room');
}

.menuPropertiesParkingSpaces .p-menuitem-icon {
  @include use-md-icon('garage');
}


.menuPropertiesFloorPlans .p-menuitem-icon {
  @include use-md-icon('square_foot');
}

.menuPropertiesMeters .p-menuitem-icon {
  @include use-md-icon('gas_meter');
}

.menuPropertiesRooms .p-menuitem-icon {
  @include use-md-icon('meeting_room');
}

.menuPropertiesFurnishings .p-menuitem-icon {
  @include use-md-icon('chair');
}

.keys-icon .p-menuitem-icon {
  @include use-md-icon('key');
}

.menuPropertiesExposes .p-menuitem-icon {
  @include use-md-icon('photo_library');
}


/*MANAGEMENT NAVIGATION ICONS*/
.menuManagementAdditionalCosts .p-menuitem-icon {
  @include use-md-icon('receipt_long');
}

.menuManagementHouseRules .p-menuitem-icon {
  @include use-md-icon('rule');
}

.menuManagementRepairs .p-menuitem-icon {
  @include use-md-icon('construction');
}

.menuManagementMaintenance .p-menuitem-icon {
  @include use-md-icon('settings');
}

.menuManagementCaretakers .p-menuitem-icon {
  @include use-md-icon('badge');
}

.menuManagementCaretakerPortal .p-menuitem-icon {
  @include use-md-icon('open_in_new');
}

/*RENTAL NAVIGATION ICONS*/
.menuRentalTenants .p-menuitem-icon {
  @include use-md-icon('diversity_1');
}


/*SALES NAVIGATION ICONS*/
.menuSalesListings .p-menuitem-icon {
  @include use-md-icon('filter');
}

.menuSalesDocuments .p-menuitem-icon {
  @include use-md-icon('folder_zip');
}

.menuSalesRequests .p-menuitem-icon {
  @include use-md-icon('text_snippet');
}


/*USER NAVIGATION ICONS*/


/*MASTER NAVIGATION ICONS*/


/*TABLE ICONS*/
.trash-icon .p-button-icon {
  @include use-md-icon('delete');
}

.trash-icon-delete .p-button-icon {
  @include use-md-icon('delete_forever');
}

.add-icon .p-button-icon {
  @include use-md-icon('add_box');
}

.add-filter .p-button-icon {
  @include use-md-icon('format_list_bulleted_add');
}

.table-toolbar .add-person-button .p-button-icon {
  @include use-md-icon('person_add');
}

.p-toolbar-group-end .add-filter-list-button .p-button-icon {
  @include use-md-icon('control_point_duplicate');
}

.crud-table .table-button-send-mail .p-button-icon {
  @include use-md-icon('forward_to_inbox');
}

.crud-table .table-button-send-email .p-button-icon {
  @include use-md-icon('send');
}

.crud-table .table-button-call .p-button-icon {
  @include use-md-icon('call');
}

.crud-table .table-button-sms .p-button-icon {
  @include use-md-icon('sms');
}

/*TABLE EDIT ICONS*/
/*TABLE EDIT ICONS -- DELETE */
.crud-table-edit .trash-button .p-button-icon {
  @include use-md-icon('delete');
}

/*TABLE EDIT ICONS -- EDIT */
.crud-table-edit .edit-button .p-button-icon {
  @include use-md-icon('edit');
}

/*TABLE EDIT ICONS -- CLOSE */
.p-dialog-header-close-icon {
  @include use-md-icon('close');
}

/*TABLE EDIT ICONS -- CANCEL */
.cancel-button .p-button-icon {
  @include use-md-icon('close');
}

:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
  transition: box-shadow 2s;
}

.error-text {
  color: $color-status-error;
}

.spinner {
  text-align: center;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 2s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: #0684ff;
  }
  40% {
    stroke: #0071e0;
  }
  66% {
    stroke: #0066d0;
  }
  80%,
  90% {
    stroke: #0071e0;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}


/* Custom styles for all toasts */
body {


  margin: 0;
  padding: 0;

  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon::before {
    content: '\e989' !important; /* This is the Unicode for the pi-times-circle icon */
  }


  .p-toast .p-toast-message {
    background-color: #2c2929 !important;
    color: #fff !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;

    .p-toast-message-content {
      padding: 10px;

      .p-toast-detail {
        font-size: 14px !important;
      }

    }
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {

  }

  .p-toast .p-toast-message-icon {
    margin-left: 5px !important;
    color: #fff !important;
  }

  .p-toast .p-toast-icon-close {
    margin-left: 5px !important;
    font-size: 24px !important;
    color: #fff !important;
    min-width: 2rem !important;
    border: none;
    border-radius: 50% !important;
  }


  .p-toast .p-toast-message-summary {
    margin-top: 10px !important;
    font-weight: bold !important;
  }

}

.no-word-break {
    white-space: nowrap;
}



