
// Brand Colors
$color-brand-primary:        #0071e0;

// Surface Colors
$color-surface-ground:       #212121;
$color-surface-card:         #303030;
$color-surface-overlay:      rgba(0, 0, 0, 0.1);

// Background Colors
$color-background-primary:   #171717;

// Text Colors
$color-text-primary:         #212121;
$color-text-secondary:       #757575;
$color-text-disabled:        #bdbdbd;
$color-text-inverted:        #ffffff;

// Border Colors
$color-border-primary:       #e0e0e0;
$color-border-secondary:     #d1d5db;

// Status Colors
$color-status-success:       #22c55e;
$color-status-warning:       #f59e0b;
$color-status-error:         #FF6B6B;
$color-status-info:          #0ea5e9;
