@import "all-abstracts";


/* Apply red border when p-password is invalid */
p-password.ng-dirty.ng-invalid .p-inputwrapper,
p-password.ng-touched.ng-invalid .p-inputwrapper {
  border: 1px solid $color-status-error;
  border-radius: 6px;
}



