@import "../abstracts/colors";
@import "./components/p-password";

// Complete custom override for Vela Blue theme

// Color palette
$primary-color: #0070e0; // Replace with your brand’s primary color
$secondary-color: #303030; // Replace with your brand’s secondary color
$accent-color: #212121; // Custom accent color

// Typography
$font-family: 'Open Sans', 'Roboto';
$font-size-base: 16px;
$font-weight: 400;
$font-color: #ededed;
$font-color-surface-ground: #9c9c9c;

// Background and surfaces
$background-color: #171717;
$surface-ground: #303030;
$surface-card: #303030;

// Borders and shadows
$border-radius: 12px;
$box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

// Spacing and layout
$spacing-unit: 16px;
$container-padding: 24px;
$gutter-width: 16px;




// Custom component styles
.p-component {
  font-family: $font-family;
  font-size: $font-size-base;
}


.p-button {
  background-color: $primary-color;
  border-radius: $border-radius;
  border: none;
  color: #fff;
  &:hover {
    cursor: pointer;
    background-color: darken($primary-color, 10%);
  }
}

.p-button-secondary {
  background-color: $secondary-color;
  border-radius: $border-radius;
  border: none;
  color: #fff;
  &:hover {
    cursor: pointer;
    background-color: darken($secondary-color, 5%);
  }
}

.p-toast {
  font-family: $font-family;
}


.p-confirm-dialog {
  .p-confirm-dialog-reject {
    height: 48px!important;
  }
  .p-confirm-dialog-accept {
    height: 48px!important;
  }
}


.p-tabmenu {
  .p-tabmenu-nav {
    .p-tabmenuitem {
      .p-menuitem-link:hover {
        color: #fff;
      }

      &.p-highlight {
        .p-menuitem-link {
          border: none;
          color: #fff;
        }
      }
    }
  }
}

.p-dialog {
  border-radius: $border-radius;
  background-color: $surface-card;
}

.p-card {
  background-color: $surface-ground;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.p-chip {
}


.form-control label {
  margin-left: 3px;
}
.p-inputtext {
  color: $font-color-surface-ground;
  background-color: $surface-ground;
  border-color: #303030;
  border-radius: 6px;
  height: 48px;
  &:focus {
    outline: none;
    border-color: #303030;
    box-shadow: none!important;
  }

  &:hover {
    outline: none;
    border-color: #303030;
    box-shadow: none!important;
  }


  &.ng-dirty.ng-invalid,
  &.ng-touched.ng-invalid {
    border-color: $color-status-error;
  }

  &::placeholder {
    color: $font-color-surface-ground;
  }
}


.p-dialog {
  border-radius: 12px;
}


.p-datatable th {
  color: $font-color-surface-ground;
}

.p-datatable td {
}

// Additional global styles
body {
  font-family: $font-family;
  font-size: $font-size-base;
  background-color: $background-color;
}

input, textarea, select {
  font-size: $font-size-base;
}

